import en from './locale/en';
import es from './locale/es';
import ca from './locale/ca';
import pt from './locale/pt';
import pt_BR from './locale/pt_BR';

export default {
  en,
  es,
  ca,
  pt_BR,
  pt,
};
